body{
    max-width: 640px;
    color: #333;
    background-color: #fff;
    margin: 0 auto;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
}
input ,textarea{
    -webkit-appearance:none;
    outline:none;
    border: solid 1px #ddd;
    border-radius: 0px;
    margin: 0;
    box-shadow: none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus{outline:none}

a,a:focus,a:hover,a:active,a:visited{
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}

.dav-red{
    color: #D73C6B;
}
.dav-blue{
    color: #0076FF;
}
.dav-bg-red{
    background-color: #D73C6B;
    color: #fff;
}
.dav-color-normal{
    color: #333;
}
input:focus,textarea:focus{
    outline: none;
}
.dav-btn{
    border-radius: 3px;
    display: inline-block;
}

.dav-btn.btn-block{
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.dav-btn.btn-red{
    background-color: #D73C6B;
    color: #fff;
}
.dav-btn.btn-disable{
    background-color: #ddd;
    color: #666;
}

.dav-color-white{
    color: #fff!important;
}

.dav-opacity{
    background: none;
    box-shadow: none;
}

img{
    max-width: 100%;
    vertical-align: middle;
}

.dav-color6,.c6{
    color: #666;
}
.dav-color9{
    color: #999;
}
.dav-green{
    color: #0f9d58;
}
.center{
    text-align: center;
    width: 100%;
}
.tc{
    text-align: center;
}

.hide{
    display: none !important;
}

a,a:focus,a:hover,a:active,a:visited{
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}
div, span{
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}

.clearfix {
    *zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    line-height: 0;
    content: "";
}

.clearfix:after {
    clear: both;
}

.pull-left{
    float: left;
}
.pull-right{
    float: right;
}

.break{
    text-decoration: line-through;
}
.underline{
    text-decoration: underline;
}

.mt60{
    margin-top: 60px;
}
.mt_10{
    margin-top: 10px;
}
.mb_1 {
    margin-bottom: 1px;
}
.mt_20{
    margin-top: 20px;
}
.mb_15{
    margin-bottom: 15px;
}
.pt_3{
    padding-top: 3px;
}
.pl_5{padding-left: 5px;}

div,
dl,dt,dd,ul,ol,li,i,
h1,h2,h3,h4,h5,h6,
pre,code,form,fieldset,legend,input,textarea,
p,blockquote,th,td,
header,nav,section,article,footer,aside{
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    list-style-type:none;
}
.fz_11 {
    font-size: 11px;
}
.fz_12 {
    font-size: 12px;
}
.fz_13 {
    font-size: 13px;
}
.fz_14 {
    font-size: 14px;
}
.fz_15 {
    font-size: 15px;
}
.fz_16 {
    font-size: 16px;
}

/*单条 border*/
.one_border.hairlines .border_inner{
    position: relative;
    border:none;
}
.one_border.hairlines .border_inner:after{
    content: '';
    position: absolute;
    left: 0;
    background: #999;
    width: 100%;
    height: 1px;
}
/*四条 border*/
.april_border.hairlines .border_inner{
    position: relative;
    border:none;
}
.april_border.hairlines .border_inner:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #999;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
}
/*Retina屏为2的时候调用下面的样式*/
@media
only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-device-pixel-ratio:2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:4/2),
only screen and (min-resolution:2dppx)
{
    .one_border.hairlines .border_inner:after{
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    .april_border.hairlines .border_inner:after{
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: left top;

        -ms-transform: scale(0.5); 		/* IE 9 */
        -ms-transform-origin: left top; 		/* IE 9 */

        -webkit-transform: scale(0.5);	/* Safari 和 Chrome */
        -webkit-transform-origin: left top;	/* Safari 和 Chrome */

        -moz-transform: scale(0.5);		/* Firefox */
        -moz-transform-origin: left top;		/* Firefox */

        -o-transform: scale(0.5);		/* Opera */
        -o-transform-origin: left top;		/* Opera */
        border-radius: 6px;
    }
}
/*# sourceMappingURL=../maps/base-da9e25fd84.css.map */
